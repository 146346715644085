import React from 'react';

import { Box } from '@core';

import { LogoBar } from '@containers';

const Logo = ({ button, backgroundColor, padding }) => (
  <Box {...padding}>
    <LogoBar button={button} backgroundColor={backgroundColor} />
  </Box>
);

export default Logo;
